import React from "react"

// https://lineicons.com/icons/

const getViewBox = name => {
  switch (name) {
    case "twitter":
      return "0 0 400 400"
    case "linkedIn":
      return "0 0 64 64"
    default:
      return "0 0 32 32"
  }
}

const getPath = name => {
  switch (name) {
    case "twitter":
      return (
        <path d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23" />
      )
    case "linkedIn":
      return (
        <path
          d="M59.3,0H4.7C2.1,0,0,2.1,0,4.6v54.8C0,61.9,2.1,64,4.7,64h54.5c2.6,0,4.7-2.1,4.7-4.6V4.6C64,2.1,61.9,0,59.3,0z M19,54.5
	H9.5V24H19V54.5z M14.2,19.8c-3,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5C19.7,17.4,17.3,19.8,14.2,19.8z M54.5,54.5
	H45V39.7c0-3.5-0.1-8.1-4.9-8.1c-4.9,0-5.7,3.9-5.7,7.8v15.1h-9.5V24H34v4.2h0.1c1.3-2.4,4.4-4.9,9-4.9c9.6,0,11.4,6.3,11.4,14.6
	L54.5,54.5L54.5,54.5z"
        />
      )
    default:
      return <path />
  }
}

const SVG = ({
  name = "",
  style = {},
  fill = "#444",
  width = "100%",
  className = "",
  height = "100%",
  viewBox = "",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox || getViewBox(name)}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name)}
  </svg>
)

export default SVG
