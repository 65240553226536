import Typography from "typography"
import USStandard from "typography-theme-us-web-design-standards"

/*const typography = new Typography(
  {
    googleFonts: [
      {
        name: "Source Sans Pro",
        styles: ["100", "200", "300", "400", "500", "600"],
      },
      {
        name: "Merriweather",
        styles: ["400", "400i", "700", "700i"],
      },
    ],
  },
  USStandard
)*/

const typography = new Typography(USStandard)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
