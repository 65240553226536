import React, { useEffect } from "react"
import { Link } from "gatsby"
import { gsap, Power3 } from "gsap"
import SVG from "./svg"

function Header() {
  const header = React.createRef(),
    name = React.createRef(),
    surname = React.createRef(),
    twitterIcon = React.createRef(),
    linkedInIcon = React.createRef()

  useEffect(() => {
    gsap
      .timeline({ delay: 0.2 })
      .to(header.current, {
        top: 0,
        duration: 1,
      })
      .from(
        name.current,
        {
          top: 13,
          duration: 1.5,
          force3D: true,
          ease: Power3.easeInOut,
        },
        "-=0.2"
      )
      .from(
        surname.current,
        {
          top: 13,
          duration: 1.5,
          force3D: true,
          ease: Power3.easeInOut,
        },
        "-=1.3"
      )
      .from(
        twitterIcon.current,
        {
          top: 6,
          opacity: 0,
          duration: 1.5,
          force3D: true,
          ease: Power3.easeInOut,
        },
        "-=1"
      )
      .from(
        linkedInIcon.current,
        {
          top: 6,
          opacity: 0,
          duration: 1.5,
          force3D: true,
          ease: Power3.easeInOut,
        },
        "-=1.3"
      )
      .timeScale(2.5)
  }, [])

  return (
    <header ref={header}>
      <div>
        <div className="box">
          <Link to={`/`}>
            <span className="name" ref={name}>
              Alex
            </span>{" "}
            <span className="surname" ref={surname}>
              Szeremeta
            </span>
          </Link>
        </div>
        <div className="social">
          <a
            href="https://twitter.com/szeremeta"
            target="_blank"
            ref={twitterIcon}
          >
            <SVG name="twitter" className="twitterIcon" />
          </a>
          <a
            href="https://www.linkedin.com/in/alexszeremeta"
            target="_blank"
            ref={linkedInIcon}
          >
            <SVG name="linkedIn" className="linkedInIcon" />
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
