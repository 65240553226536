import React from "react"
import Header from "./header"
import { Helmet } from "react-helmet"

import "../styles/index.css"

function Layout(props) {
  return (
    <div className="wrapper">
      <Header />
      <main>{props.children}</main>
      <footer></footer>
    </div>
  )
}

export default Layout
